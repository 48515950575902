<template>
  <div style="display:flex;">
    <el-col :span="4">
      <el-menu :default-active="this.$route.path" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose"
        router>
        <el-menu-item v-for="(item,index) in navList" :index="item.path" :key="index">
          <i :class="item.icon"></i>
          <span slot="title">{{item.navItem}}</span>
        </el-menu-item>

        <el-submenu index="1">
          <template slot="title">
            <i class="el-icon-user"></i>
            <span>账号管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="AccountInformation">账号信息</el-menu-item>
            <el-menu-item index="ChangeBind">换绑手机号</el-menu-item>
            <el-menu-item index="ChangePassword">修改密码</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <el-menu-item index="Help">
          <i class="el-icon-service"></i>
          <span slot="title">帮助反馈</span>
        </el-menu-item>
      </el-menu>
    </el-col>

    <router-view></router-view>

  </div>
</template>

<script>
export default {
  data() {
    return {
      navList: [
        {
          path: "/PersonalCenter/a",
          navItem: "个人主页",
          icon: "el-icon-s-home",
        },
        {
          path: "/PersonalCenter/IWantComplain",
          navItem: "我要投诉",
          icon: "el-icon-edit",
        },
        {
          path: "/PersonalCenter/MyComplaint",
          navItem: "我的投诉",
          icon: "el-icon-document",
        },
        {
          path: "/PersonalCenter/MyCollection",
          navItem: "我的收藏",
          icon: "el-icon-star-off",
        },
        {
          path: "/PersonalCenter/CommentManagement",
          navItem: "评论管理",
          icon: "el-icon-chat-dot-square",
        },
        {
          path: "/PersonalCenter/NoticeNotice",
          navItem: "通知公告",
          icon: "el-icon-bell",
        },
      ],
    };
  },
  methods: {
    handleOpen(key, keyPath) {
      //console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      //console.log(key, keyPath);
    },
  },
};
</script>

<style scoped>
>>> .el-menu-vertical-demo {
  text-align: center;
}
>>> .el-col{
  float:none !important;
}
>>> .el-menu-item:focus, .el-menu-item:hover{
  background:#1F72D9;
  color:#fff;
}
</style>